import React, { useEffect, useMemo } from 'react'
import {
  MainNav,
  NavWrapper,
  OnScrollLogoLink,
  SlideHoverCta,
  UtilityNavWrapper,
  WrapperNavigation,
} from './NavigationBar.style'
import NavigationMenuDesktop from './components/NavigationMenuDesktop'
import Container from '@components/UI/Container'
import { useTranslation } from 'next-i18next'
import { CART } from '@constants/routes'
import { IconBasket } from '@components/UI/Icons/VD/General'
import CurrencyService from '@services/CurrencyService'
import { useSelector } from 'react-redux'
import { cartSelector, orderItemsSelector, isCartThresholdReachedSelector } from '@features/order/selector'
import { useSite } from '@foundation/hooks/useSite'
import MyAccountNavigation from './components/MyAccountNavigation'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import Logo from '@components/UI/Logo'
import { IOrderItem } from '@typesApp/order'
import { getParsedOrderItems, calculateGiftProductsDiscounts } from '@utils/order'
import { replaceLocaleInUrl } from '@utils/locale'
import { useRouter } from 'next/router'
import { locationOriginSelector } from '@redux/selectors/site'
import { CartThresholdReachedModal } from '@components/CartThresholdReachedModal'

const NavigationBar: React.FC<{
  isSticky?: boolean | null
}> = ({ isSticky = false }) => {
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const router = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const homePageURL = replaceLocaleInUrl({
    isLocaleDomain: router.isLocaleDomain,
    locationOrigin,
    locale: router.locale,
    href: `/${langCode}`,
  }) as string
  const { t } = useTranslation()
  const cart = useSelector(cartSelector)
  const orderItems = useSelector(orderItemsSelector)

  // Calculate subtatal considering the discount
  const parsedOrderItems = useMemo<IOrderItem[] | null>(
    () => (!!orderItems ? getParsedOrderItems(orderItems) : null),
    [orderItems]
  )
  const totalProductPrice = cart?.totalProductPrice ? parseFloat(cart?.totalProductPrice) : 0
  const parsedOrderItemsList = useMemo(() => parsedOrderItems || [], [parsedOrderItems])
  const giftProductsDiscounts = calculateGiftProductsDiscounts(parsedOrderItemsList)
  const partialSubtotal =
    giftProductsDiscounts > 0 && totalProductPrice > 0 ? totalProductPrice - giftProductsDiscounts : totalProductPrice
  const formattedSubTotal = CurrencyService.formatValueWithCurrency({
    currency: cart?.grandTotalCurrency,
    value: partialSubtotal,
    locale: mySite?.locale,
  })

  const isCartThresholdReached = useSelector(isCartThresholdReachedSelector)

  useEffect(() => {
    const handleMouseDown = event => {
      let target = event.target

      while (target && target !== document) {
        if (target.getAttribute('data-tealium-ignore') === 'true') {
          event.stopPropagation()
          return
        }
        target = target.parentElement
      }
    }

    document.addEventListener('mousedown', handleMouseDown, true)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown, true)
    }
  }, [])

  return (
    <>
      {isCartThresholdReached && (
        <CartThresholdReachedModal
          grandTotal={cart?.grandTotal}
          currency={cart?.grandTotalCurrency}
          isOpen={isCartThresholdReached}
        />
      )}
      <WrapperNavigation>
        <Container>
          <NavWrapper>
            <MainNav>
              <OnScrollLogoLink isSticky={isSticky} href={homePageURL}>
                <Logo variant="white" showSymbolOnly />
              </OnScrollLogoLink>
              <NavigationMenuDesktop />
            </MainNav>
            <UtilityNavWrapper>
              <MyAccountNavigation />
              <SlideHoverCta
                isBasket
                prefetch={false}
                href={`/${CART}`}
                data-name={'miniBasket'}
                data-tealium-ignore="true"
                aria-label={t('Header.Actions.Cart')}
                onClick={() => {
                  if (window?.tealium_data2track) {
                    const tealiumPayload = {
                      id: 'Click',
                      Click_FocusElement: '[ HTMLAnchorElement ]',
                      data_element_id: 'MainNav_Bag',
                      data_description: '',
                      data_analytics_available_call: '1',
                    }
                    window.tealium_data2track.push(tealiumPayload)
                  }
                }}
              >
                <IconBasket />
                <span>{formattedSubTotal}</span>
              </SlideHoverCta>
            </UtilityNavWrapper>
          </NavWrapper>
        </Container>
      </WrapperNavigation>
    </>
  )
}

export default NavigationBar
